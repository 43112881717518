export const CONNECTED="CONNECTED";
export const LOAD_USER_DATA="LOAD_USER_DATA";
export const USER_INFOS_DATA="USER_INFOS_DATA";
export const GET_ALL_STOCK_PRODUCT="GET_ALL_STOCK_PRODUCT";
export const UPDATE_STOCK_PRODUCT="UPDATE_STOCK_PRODUCT";
export const GET_SINGLE_CLIENT="GET_SINGLE_CLIENT";
export const UPDATE_SINGLE_CLIENT="UPDATE_SINGLE_CLIENT";
export const GET_CATEGORY="GET_CATEGORY";
export const UPDATE_CATEGORY="UPDATE_CATEGORY";
export const ADD_CART="ADD_CART";
export const UPDATE_CART="UPDATE_CART";
export const GET_CLIENT="GET_CLIENT";
export const UPDATE_CLIENT="UPDATE_CLIENT";
export const GET_USER_STORE="GET_USER_STORE";
export const UPDATE_USER_STORE="UPDATE_USER_STORE";
export const REFRESH_VIEW="REFRESH_VIEW";
export const LOAD_PAIEMENT="LOAD_PAIEMENT";
export const UPDATE_STORE_COMMANDE="UPDATE_STORE_COMMANDE";
export const UPDATE_DETAIL_STORE_COMMANDE="UPDATE_DETAIL_STORE_COMMANDE";
export const ADD_USER_STORE_RATING="ADD_USER_STORE_RATING";
export const UPDATE_USER_STORE_RATING="UPDATE_USER_STORE_RATING";
export const GET_USER_COORDINATES="GET_USER_COORDINATES";